<div *ngIf="loading">
    <div class="align-items-center">
        <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
</div>

<div *ngIf="clusterId != null && stats">
    <div class="grid">
        <p-card header="{{'LABELS.users' | translate | titlecase}}" class="col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{stats.users}}</span>
                <i class="pi pi-users text-5xl"></i>
            </div>
        </p-card>
        <p-card header="{{'LABELS.organizations' | translate | titlecase}}" class=" col-12 md:col-4" styleClass="bg-primary">
            <div class="flex flex-row justify-content-between">
                <span class="text-3xl">{{stats.organizations}}</span>
                <i class="pi pi-shield text-5xl"></i>
            </div>
        </p-card>
    </div>
    <p-button [label]="'LABELS.edit-cluster' | translate" (onClick)="editClusterButtonClicked()" [outlined]=true></p-button>
</div>
